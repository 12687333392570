import React from 'react'
import './Logo.css'

export default () => (
  <div
    className="Logo"
    /* style={{
      backgroundImage: `url(/images/logo.png)`
    }} */
  >
    SafePlace
  </div>
)
